* {
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

button,.inter{
  font-family: "Inter", sans-serif !important;
}

dialog::backdrop {
  backdrop-filter: blur(3px);
}
select{
  cursor: pointer;
}

.modal{
  padding: 50px;
}

#modal {
  background-color: #ffffff;
  border-radius: 50px;
  width: 100%;
  max-width: 607px;
}

textarea{
  resize: none;
}

footer{
  padding: 30px 20px;
  background-image: url("./assets/Blur.jpg");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

.illustr{
  background-image: url("./assets/ptrns.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.dashboard-grid{
  display: flex;
  gap: 5;
  flex-wrap: wrap;
}

dialog,input{
  outline: none;
}
.react-datepicker__input-container input{
  height: 40px;
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

input[type=checkbox]{
  accent-color:  #DEDEDE;  
  outline: none;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}


@media only screen and (max-width: 450px) {
  footer{
    padding: 30px 20px;
    background-image: url("./assets/Blur2.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .illustr{
    background-image: url("./assets/ptrns2.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  dialog{
    height: 100vh;
    width: 100%;

  }
  dialog::backdrop {
    overflow-y: hidden;
  }
  .modal{
    padding: 50px 20px;
  }
}