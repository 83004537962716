.btn {
    outline: none;
    /* height: 36px; */
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27.28px;
    text-align: center;
    padding: 16px 20px;
    font-family: 'Inter', 'Montserrat';
}
.btn--small {
    /* max-width: 120px; */
    width: auto;
}
.btn--medium {
    width: 200px;
}
.btn--large {
    width: 100%;
}
.btn--primary {
    background-color: #EC008C;
    border: none;
    color: #FFFFFF;
}
.btn--primary.disabled{
    background-color: #EC008C33;
}
.btn--primary:hover {
    opacity: 0.9;
}
.btn--secondary, .btn--secondary2 {
    background-color: #FFDAEB;
    border: none;
    color: #F1488E;
}
.btn--secondary2{
    color: #000000;
}
.btn--secondary:hover {
    opacity: 0.6;
}
.btn--danger {
    background: #dc2626;
    border: none;
    color: #fff;
}
.btn--tertiary {
    background-color: #FFFFFF;
    border: none;
    color: #EC008C;
}
.lndng-btn{
    font-size: 24px;
    line-height: 29px;
}

@media only screen and (max-width: 450px) {
    .btn {
        font-size: 16px;
        line-height: 21.82px;
    }
    .btn--medium, .lndng-btn{
        width: auto;
    }
}