.input_field{
    width: 100%;
    padding: 8px 16px;
    border: 1px solid #E8E8EA;
    border-radius: 18px;
    background-color: #fff;
}
.input_field .input-field {
    width: 100%;
    height: 40px;
    background: #fff;
    padding: 0px 8px;
}
.input_field .input-field:focus {
    outline: none;
}
.input_field .show-password {
    margin-top: 13.5px;
    margin-left: -40px;
}
.input_field .error-message {
    color: red;
    margin-top: 5px;
    font-size: 11px;
}
.input_field .disabled {
    color: #9a9ab0 !important;
}

::placeholder{
    color: #A5A3A9;
    font-weight: 400;
    font-size: 14px;
}

textarea{
    outline: none;
    border: none;
    height: 80px;
    width: 100%;
}
