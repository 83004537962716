
.main-image {
    width: 100%;
    height: 480px;
    object-fit: cover;
    z-index: 10;
    position: relative;
    transition: border 0.3s ease;
  }
  
  .list1:hover .main-image {
    border: 2px solid rgb(255, 0, 144);
  }
  
  .overlay-images {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: space-between;
    z-index: 5;
  }
  
  .list1:hover .overlay-images {
    opacity: 1;
  }
  
  .overlay-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
    position: absolute;
    transition: transform 0.3s ease;
  }
  .overlay-img1,
  .overlay-img3 {
    left: -20%;
    transform: translateX(-100%);
  }
  
  .overlay-img2,
  .overlay-img4 {
    right: -20%;
    transform: translateX(100%);
  }
  
  .overlay-img1 {
    top: 0;
    left: -20%;
    transform: translate(-100%, 0) rotate(-10deg);
  }
  
  .overlay-img2 {
    top: 0;
    right: -20%;
    transform: translate(100%, 0) rotate(10deg);
  }
  
  .overlay-img3 {
    bottom: 0;
    left: -20%;
    transform: translate(-100%, 0) rotate(10deg);
  }
  
  .overlay-img4 {
    bottom: 0;
    right: -20%;
    transform: translate(100%, 0) rotate(-10deg);
  }
  
  .list1:hover .overlay-img1 {
    transform: translate(0, 0) rotate(-10deg);
  }
  
  .list1:hover .overlay-img2 {
    transform: translate(0, 0) rotate(10deg);
  }
  
  .list1:hover .overlay-img3 {
    transform: translate(0, 0) rotate(10deg);
  }
  
  .list1:hover .overlay-img4 {
    transform: translate(0, 0) rotate(-10deg);
  }
  