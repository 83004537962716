.calendar {
    width: 100%;
    display: flex;
    flex-direction: column;
}
  
.calendar-header {
    height: 47px;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 27.3px;
}
  
.calendar-body {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
  
.table-header {
    height: 37px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.weekday {
    /* width: 30px; */
    margin: 0px auto;
    font-size: 14px;
    line-height: 19.1px;
    font-weight: 600;
    color: #333333;
}

.table-content {
    width: 100%;
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 16px;
    box-sizing: border-box;
}

.calendar-day {
    width: 30px;
    height: 37px;
    position: relative;
    margin: 0px auto;
    cursor: pointer;
}

/* .calendar-day:hover {
    background-color: rgba(0,0,0,.10);
} */

.calendar-day p {
    /* position: absolute; */
    right: 10px;
    color: #a6a6a6;
}

.current p {
    color: #000000;
}

.selected p {
    width: 29px; 
    height: 29px;
    background-color: #EC008C;
    border-radius: 99px;
    text-align: center;
    padding: 3px;
    color: #FFFFFF;
    font-weight: 600;
}

.schedule , .multiple {
    border: 2px solid #403691;
    border-radius: 10px;
    padding: 4px;
    height: 37px;
}

.multiple{
    border: 2px solid #E0AE00;
}

.schedule p{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
    margin-top: -7px;
    padding-top: 6px;
}